import { colorTheme } from "@/design/colors/mdColors";

/**
 * snackbar-able Mixin
 */
export const snackbarableMixin = {
  data() {
    return {
      snackbarVisible: false,
      snackbarColor: "",
      snackbarText: "",
      snackbarTimeout: 2000,
      snackbarVisibleCloseButton: true,
      snackbarTop: false,
      snackbarMultiLine: false
    };
  },
  components: {
    Snackbar: () => import("@/components/shared/base/BaseSnackbar")
  },
  methods: {
    /**
     * show Snackbar Success
     * @param {String} text
     * @param {Boolean} top
     */
    showSnackbarSuccess(text, top = false) {
      this.showSnackbar(text, colorTheme.success, top);
    },
    /**
     * show Snackbar Info
     * @param {String} text
     * @param {Boolean} top
     */
    showSnackbarInfo(text, top = false) {
      this.showSnackbar(text, colorTheme.info, top);
    },
    /**
     * show Snackbar Error (timeout: Use -1 to keep open indefinitely)
     * @param {String} text
     * @param {Boolean} top
     * @param {Number|number} timeout
     * @param {Boolean} multiLine
     */
    showSnackbarError(text, top = false, timeout = -1, multiLine = true) {
      this.showSnackbar(text, colorTheme.error, top, timeout, multiLine);
    },
    /**
     * show Snackbar Warning
     * @param {String} text
     * @param {Boolean} top
     */
    showSnackbarWarning(text, top = false) {
      this.showSnackbar(text, colorTheme.warning, top);
    },
    /**
     * show Snackbar
     * @param {String} text
     * @param {String} color
     * @param {Boolean} top
     * @param {Number|number} timeout
     * @param {Boolean} multiLine
     */
    showSnackbar(
      text,
      color = undefined,
      top = false,
      timeout = 2000,
      multiLine = false
    ) {
      this.snackbarVisible = true;
      this.snackbarColor = color;
      this.snackbarText = text;
      this.snackbarTop = top;
      this.snackbarTimeout = timeout;
      this.snackbarMultiLine = multiLine;
    },
    /**
     * close Snackbar
     */
    closeSnackbar() {
      this.snackbarVisible = false;
    }
  }
};
