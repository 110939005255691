// router
import { createModuleItemContentRouteLocation } from "@/router/solution/solutionRoutes";

// mixins
import { appMixin } from "@/mixins/shared/base/app/appMixin";
import { routerMixin } from "@/mixins/shared/router/routerMixin";

/**
 * Contains options (adhere to the DRY principle) & code re-usability
 * for components that needs Application module navigatable behavior
 */
export const moduleItemNavigatable = {
  mixins: [appMixin, routerMixin],
  methods: {
    /**
     * Navigate to Application Module Item
     * @param {Number|number} id Application Module Item id
     */
    async navigateToModuleItem(id) {
      if (!this.canNavigateToModuleItem(id)) {
        console.warn(
          `Couldn't navigate to ${this.appModuleName} Item by id:`,
          id
        );
        return;
      }

      try {
        await this.$router.push(this.createModuleItemContentRoute(id));
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * create Module Item Target Route
     * @param {Number|number} id Module Item id
     * @return {{name: (String|string), params: {id:number}}}
     */
    createModuleItemContentRoute(id) {
      return createModuleItemContentRouteLocation(this.appModuleName, id);
    },

    /**
     * can Navigate To Module Item
     * @param {Number|number} id Module Item id
     * @return {boolean} true if Navigatable To Module Item
     */
    canNavigateToModuleItem(id) {
      return this.canNavigateToModuleItemByModuleItemId(this.appModuleName, id);
    }
  }
};
