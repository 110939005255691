// services
import { isStateOwner } from "@/services/record/recordService";

// model
import { featureStatus } from "@/model/features/featuresModel";
import { moduleNames } from "@/model/solution/moduleModel";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("user");

import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

/**
 * Encapsulate Questys principal
 */
export const principalMixin = {
  mixins: [publicAccessMixin],
  computed: {
    ...mapGetters({
      principal: "principal",
      findFeature: "findFeature",
      isAvailableApplicationModule: "isAvailableApplicationModule"
    }),

    /**
     * compute whether workflow projects module is available (licensed, supported, enabled)
     * @return {boolean} true if workflow projects module is available
     */
    isAvailableProjectsModule() {
      return this.isAvailableApplicationModule(moduleNames.Projects);
    },

    /**
     * compute whether workflow my inbox (tasks) module is available (licensed, supported, enabled)
     * @return {boolean} true if workflow my inbox (tasks) module is available
     */
    isAvailableMyInboxModule() {
      return this.isAvailableApplicationModule(moduleNames.Tasks);
    },

    /**
     * compute whether Agenda module is available (licensed, supported, enabled)
     * @return {boolean} true if Agenda module is available
     */
    isAvailableAgendaModule() {
      return this.isAvailableApplicationModule(moduleNames.Agenda);
    }
  },
  methods: {
    /**
     * determines whether the current user (principal) is State Owner of specified record
     * @param {{id: number, name: string, stateOwnerId: number}} record
     * @return {boolean} returns true if current user (principal) is State Owner of specified record
     */
    isUserStateOwner(record) {
      return isStateOwner(record, this.principal);
    },

    /**
     * is Available Feature
     * @param {string} name Feature name
     * @return {boolean} true if Feature is Available (supported, licenced, released)
     */
    isAvailableFeature(name) {
      const status = this.featureStatus(name);
      return status.supported && status.licenced && status.released;
    },

    /**
     * get feature Status
     * @param name
     * @return {{licenced: boolean, released: boolean, statusText: string, supported: boolean, notSupportedReason: string}}
     */
    featureStatus(name) {
      let feature = this.findFeature(name);
      if (!feature) {
        feature = {
          name: name,
          used: 1,
          total: 1
        };
      }
      return featureStatus(feature);
    }
  }
};
