<template>
  <base-content-layout
    :visible-data-table-in="visibleDataTable"
    :error="currentError"
    :loading="isLoading"
    :record="record"
    v-on:alertVisibilityChanged="onAlertVisibilityChanged"
    v-on:expandedPreview="onExpandedPreview"
  >
    <!-- breadcrumbs -->
    <template v-slot:breadcrumbs>
      <breadcrumbs-record
        :breadcrumbs="breadcrumbs"
        :selected-record="record"
        :can-navigate="canNavigateToModuleItem"
        :build-target-route="createModuleItemContentRoute"
      ></breadcrumbs-record>
    </template>

    <!-- record details -->
    <template v-slot:record-details>
      <agenda-record-details></agenda-record-details>
    </template>

    <!-- record projects -->
    <template v-slot:record-projects v-if="visibleProjectsTab">
      <agenda-projects v-show="visibleProjectsTab"></agenda-projects>
    </template>

    <!-- Sub record list -->
    <template v-slot:record-list v-if="visibleSubRecords">
      <agenda-documents v-show="visibleSubRecords"></agenda-documents>
    </template>

    <!-- record history -->
    <template v-slot:record-history v-if="visibleFileVersions">
      <agenda-file-versions v-show="visibleFileVersions"></agenda-file-versions>
    </template>

    <!-- Preview -->
    <template v-slot:record-preview v-if="visiblePreviewTab">
      <!--
       TODO: enable DocumentViewerPreview when conflict of
       preview/DocumentEditorDialog is resolved
     -->
      <!-- Preview record-image-pdf-file ONLY when expanded Preview -->
      <document-viewer
        v-if="visibleDocumentViewerPreview"
        :record-id="recordId"
        :record-version="previewRecordVersion"
        :preview-only="true"
        :is-open-document="expandedPreview"
      ></document-viewer>

      <!-- Preview current record's file(s) -->
      <document-preview
        v-if="visibleDocumentPreview"
        :record="record"
        :open="expandedPreview"
      ></document-preview>
    </template>

    <!-- e.g. How to use Snackbar if/when needed -->
    <template v-slot:snackbar>
      <snackbar
        v-model="snackbarVisible"
        @close="closeSnackbar"
        :color="snackbarColor"
        >{{ computedSnackbarText }}</snackbar
      >
    </template>
  </base-content-layout>
</template>

<script>
import { agendaContentMixin } from "@/mixins/agenda/content/agendaContentMixin";

export default {
  name: "AgendaContent",
  mixins: [agendaContentMixin],
  data() {
    return {
      panelDocuments: undefined,
      panelHistory: undefined
    };
  },
  components: {
    AgendaRecordDetails: () => import("@/views/agenda/AgendaRecordDetails"),
    AgendaDocuments: () => import("@/views/agenda/AgendaDocuments"),
    AgendaFileVersions: () => import("@/views/agenda/AgendaFileVersions"),
    DocumentPreview: () => import("@/views/document/DocumentPreview"),
    DocumentViewer: () => import("@/views/document/DocumentViewer"),
    BreadcrumbsRecord: () =>
      import("@/components/shared/core/breadcrumbs/BreadcrumbsRecord"),
    AgendaProjects: () => import("@/views/agenda/AgendaProjects")
  }
};
</script>
