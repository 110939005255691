import { isPublicAccess, publicAccess } from "@/services/config/configService";

/**
 * Encapsulate public Access business logic
 */
export const publicAccessMixin = {
  computed: {
    /**
     * is Public Access
     * @return {boolean} true if Public Access is enabled
     */
    isPublicAccess() {
      return isPublicAccess;
    },

    /**
     * public Access
     * @return {
     *    {searcher: {name: string, code: string, repository: string}},
     *    {appBar: {prominent: boolean, color: string, gradient: string, useTabs: boolean, logo: string, logoSize:number, backgroundImageUrl: string, title: string, collapseOnScroll: boolean, fadeImgOnScroll: boolean, shrinkOnScroll: boolean}}, {sideBar: {enableMiniSidebar: boolean, backgroundImageUrl: string}}, {sideBar: {enableMiniSidebar: boolean, backgroundImageUrl: string}},
     *    {buttons: {color: string, elevation: number, outlined: boolean, rounded: boolean, textStyle: boolean}},
     *    {company: {disclaimers: string, name: string, logo: string, description: string, linkedIn: string, address: {zip: string, country: string, city: string, addressLine1: string, addressLine2: string, state: string}}},
     *    {contact: {name: string, description: string, phone: string, phoneDescription: string, email: string, emailDescription: string}},
     *    {modules: {visibleFolder: string, visibleCategory: string, visibleMeetings: string}}
     *    }
     */
    publicAccess() {
      return publicAccess;
    }
  }
};
