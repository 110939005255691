/**
 * Get not Implemented Computed formatted optionJump
 * @param {String|string} propertyName
 * @param {Boolean|boolean} byClient
 * @returns {String|string}
 */
const notImplementedComputed = (propertyName, byClient = true) =>
  byClient
    ? `Return value of Computed property: '${propertyName}' has to be be implemented by client.`
    : `Return value of Computed property: '${propertyName}' has to be be implemented.`;

/**
 * Get not Implemented Method formatted optionJump
 * @param {String|string} propertyName
 * @param {Boolean|boolean} byClient
 * @returns {String|string}
 */
const notImplementedMethod = (propertyName, byClient = true) =>
  byClient
    ? `Method: '${propertyName}' has to be be implemented by client.`
    : `Method: '${propertyName}' has to be be implemented.`;

export { notImplementedComputed, notImplementedMethod };
