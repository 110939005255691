// services
import {
  storeSelectedModuleItemId,
  storeSelectedModuleExpandedId
} from "@/services/agenda/agendaModuleService";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapState, mapGetters, mapActions } = createNamespacedHelpers("agenda");

// mixins
import { moduleContentRecordMixin } from "@/mixins/shared/content/moduleContentRecordMixin";
import { moduleItemNavigatable } from "@/mixins/shared/navigatable/moduleItemNavigatable";

export const agendaContentMixin = {
  mixins: [moduleContentRecordMixin, moduleItemNavigatable],
  props: {
    /**
     * Folder expandedId: used as a second dynamic segment in the route path
     */
    expandedId: {
      type: Number,
      default: -1
    }
  },
  computed: {
    ...mapState({
      /**
       * Get current Agenda Module's selected record
       * Overwrite moduleContentRecordMixin.record()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[] }}
       */
      record: "record",

      /**
       * breadcrumbs
       * @return {Array<{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}>}
       */
      breadcrumbs: "breadcrumbs",

      /**
       * workflow projects
       * @return {{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}[]}
       */
      projects: "projects"
    }),

    ...mapGetters({
      /**
       * Get selected Agenda Module's Item Id
       * Overwrite moduleContentMixin.selectedModuleItemId()
       * @return {number}
       */
      selectedModuleItemId: "selectedModuleItemId",

      /**
       * Get selected Agenda Module's Item name
       * Overwrite moduleContentMixin.selectedModuleItemName()
       * @return {string}
       */
      selectedModuleItemName: "selectedModuleItemName"
    })
  },
  methods: {
    ...mapActions([
      "setRecord",
      "setBreadcrumbs",
      "setRecordShortcut",
      "setProjects"
    ]),

    /**|
     * Set current Agenda Module's item (record)
     * Overwrite moduleContentMixin.setModuleItem()
     * @param id
     * @param {Boolean} isShortcut - optional
     * @return {Promise<void>}
     */
    async setModuleItem(id, isShortcut = false) {
      console.log(
        `${this.$options.name}.setModuleItem() (this.id: ${this.id}, this.expandedId: ${this.expandedId}`
      );

      if (id !== this.id) {
        console.warn(
          `${this.$options.name}.setModuleItem(${id}) id: ${id} !== this.id: ${this.id}`
        );
      }

      // Set current record
      if (isShortcut) {
        const payload = {
          id: id,
          parentId: this.expandedId
        };
        await this.setRecordShortcut(payload);
      } else {
        await this.setRecord(id);
      }

      // Set current record's workflow projects
      if (this.isAvailableProjectsModule) {
        try {
          await this.setProjects(this.record?.id);
        } catch (e) {
          console.error(e); // provide a UI error notification, e.g. unable to set projects ...
        }
      }

      // store
      if (id === this.record?.id) {
        storeSelectedModuleItemId(this.record?.id);
        storeSelectedModuleExpandedId(this.expandedId);
      }
    }
  },
  async created() {
    console.log(
      `${this.$options.name}.created() this.expandedId:`,
      this.expandedId
    );
    console.log(`${this.$options.name}.created() this.id:`, this.id);
  }
};
