// services
import { notImplementedMethod } from "@/services/error/errorMessages";

// Mixins
import { appMixin } from "@/mixins/shared/base/app/appMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { snackbarableMixin } from "@/mixins/shared/snackbarable/snackbarableMixin";
import { createModuleExpandRecordShortcutContentRouteName } from "@/router/solution/solutionRoutes";

/**
 * This mixin serves as a base class for *Content*.vue components
 */
export const moduleContentMixin = {
  mixins: [appMixin, snackbarableMixin, baseComponentMixin],
  components: {
    BaseContentLayout: () => import("@/views/shared/layout/BaseContentLayout"),
    ExpansionPanelLayout: () =>
      import("@/components/shared/core/expansionPanel/ExpansionPanelLayout")
  },
  data() {
    return {
      /**
       * Visible Data Table
       * @type {Boolean}
       */
      visibleDataTable: true
    };
  },
  props: {
    /**
     * Module Item id: used as dynamic segment in the route path
     */
    id: {
      type: Number,
      default: -1
    }
  },
  computed: {
    /**
     * Get selected Module ItemId
     * Abstract computed getter
     * @return {number}
     */
    selectedModuleItemId() {
      return -1;
    },

    /**
     * Get selected Module Item Name
     * Abstract computed getter
     * @return {string}
     */
    selectedModuleItemName() {
      return "";
    },

    /**
     * Get Snackbar Text
     * @returns {string}
     */
    computedSnackbarText() {
      return `Selected: ${this.selectedModuleItemName || ""}`;
    }
  },
  methods: {
    /**
     * set Module Item
     * Abstract method which has to be implemented by caller
     * @param {number} id
     * @param {Boolean} isShortcut - optional
     * @return {Promise<void>}
     */
    async setModuleItem(id, isShortcut = false) {
      console.warn(
        notImplementedMethod(
          `${this.$options.name}.setModuleItem(${id})`,
          isShortcut
        )
      );
    },

    /**
     * set Current selected Module Item
     * @return {Promise<void>}
     */
    async setCurrentModuleItem(isShortcut = false) {
      try {
        this.clearError();
        this.isLoading = true;
        console.log(
          `${this.$options.name}.setCurrentModuleItem() this.id:`,
          this.id
        );
        await this.setModuleItem(this.id, isShortcut);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    }
  },

  // /**
  //  * In-Component Navigation Guards: beforeRouteEnter
  //  * @param to
  //  * @param from
  //  * @param next
  //  * @return {Promise<void>}
  //  */
  // async beforeRouteEnter(to, from, next) {
  //   console.log(`beforeRouteEnter to:`, to);
  //   // console.log(`beforeRouteEnter from:`, from);
  //
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   next(async vm => {
  //     //
  //     // access to component instance via `vm`
  //     //
  //     // console.log(`beforeRouteEnter()`, `vm.id: ${vm.id}`);
  //     // console.log(`beforeRouteEnter()`, `to?.params?.id: ${to?.params?.id}`);
  //     // console.log(
  //     //   `beforeRouteEnter()`,
  //     //   `vm.id === to?.params?.id: ${vm.id === to?.params?.id}`
  //     // );
  //     return await vm.setCurrentModuleItem();
  //   });
  // },

  // /**
  //  * In-Component Navigation Guards: beforeRouteUpdate
  //  * @param to
  //  * @param from
  //  * @param next
  //  * @return {Promise<void>}
  //  */
  // async beforeRouteUpdate(to, from, next) {
  //   try {
  //     // called when the route that renders this component has changed.
  //     // This component being reused (by using an explicit `key`)
  //     // in the new route or not doesn't change anything.
  //
  //     // const id = to?.params?.id;
  //     // console.log("beforeRouteUpdate()", `id: ${id}`);
  //
  //     // e.g. apply Navigation Guard - prevent going to 'to' route
  //     // if (id === 259704) {
  //     //   // Cancel navigation to route
  //     //   next(false);
  //     //   return;
  //     // }
  //
  //     console.log(`beforeRouteUpdate to:`, to);
  //     // console.log(`beforeRouteUpdate from:`, from);
  //
  //     await this.setCurrentModuleItem();
  //     next();
  //   } catch (e) {
  //     console.error(e.toString());
  //     next();
  //   }
  // }

  async created() {
    console.log(`${this.$options.name}.created()`);
    const isShortcut =
      this.$route.name ===
        createModuleExpandRecordShortcutContentRouteName("Folder") ?? false;
    await this.setCurrentModuleItem(isShortcut);
  },

  watch: {
    // when using routes with params, the same component instance will be reused,
    // this also means that the lifecycle hooks of the component will not be called,
    // To react to params changes in the same component, we can simply watch the $route object:
    //

    // eslint-disable-next-line no-unused-vars
    async $route(to) {
      try {
        console.log(`watch ${this.$options.name}.route to:`, to);

        //Compute if path name is Folder Shortcut
        const isShortcut =
          to.name ===
            createModuleExpandRecordShortcutContentRouteName("Folder") ?? false;

        //Pass isShortcut to get Record Shortcut Data from Api if true
        await this.setCurrentModuleItem(isShortcut);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
