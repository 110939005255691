/**
 * Contains options (adhere to the DRY principle) & code re-usability
 * for components that needs router functionality
 */
export const routerMixin = {
  methods: {
    /**
     * can Navigate To Module Item By Module Item Id
     * @param {String|string} moduleName
     * @param {Number|number} id
     * @return {boolean} true if navigatable To Module Item By Module Item id
     */
    canNavigateToModuleItemByModuleItemId(moduleName, id) {
      return !(
        this.$router.currentRoute.name === moduleName &&
        this.$router.currentRoute.params?.id?.toString() ===
          (id?.toString() ?? "")
      );
    },

    /**
     * can Navigate To Module Child Record By id
     * @param {String|string} moduleName
     * @param {Number|number} id record id
     * @param {Number|number} expandedId record's parent id
     * @return {boolean}
     */
    canNavigateToChildRecordById(moduleName, id, expandedId) {
      return !(
        this.$router.currentRoute.name === moduleName &&
        this.$router.currentRoute.params?.id?.toString() === id.toString() &&
        this.$router.currentRoute.params?.expandedId?.toString() ===
          expandedId.toString()
      );
    }
  }
};
