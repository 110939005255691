/**
 * Base Component Mixin serves as a base class for components
 */
import { isEmpty } from "@/utils";

export const baseComponentMixin = {
  data() {
    return {
      /**
       * Current Error
       * @type {string}
       */
      currentError: "",

      /**
       * Is Loading indicator
       * @type {boolean}
       */
      isLoading: false
    };
  },
  computed: {
    /**
     * Get component Name
     * @returns {string}
     */
    componentName() {
      return this.$options.name;
    },
    /**
     * Get loading Text (overwrite it if needed)
     * @returns {string}
     */
    loadingText() {
      return `Loading ...`;
    },
    /**
     * Has an Error occurred
     * @returns {boolean}
     */
    hasError() {
      return !isEmpty(this.currentError);
    }
  },
  methods: {
    /**
     * Build full Method (function) Name (this.componentName + Method Name)
     * @param {string} name
     * @returns {string}
     */
    fullMethodName(name) {
      return `${this.componentName}.${name}`;
    },
    /**
     * Handle Error
     * @param {string} error
     * @param {string} methodName
     */
    handleError(error, methodName = "") {
      this.currentError = error?.toString() ?? "";

      console.error(
        methodName ? `${this.fullMethodName(methodName)} Error:` : "Error:",
        this.currentError
      );
    },
    /**
     * Clear current error
     */
    clearError() {
      this.currentError = undefined;
    },
    /**
     * on Alert Visibility Changed event handler
     * @param {Boolean|boolean} visible
     */
    onAlertVisibilityChanged(visible) {
      if (!visible) {
        this.clearError();
      }
    }
  }
};
