// services
import {
  canPreviewInDocumentViewer,
  previewRecordVersion
} from "@/services/record/recordService";

// Mixins
import { moduleContentMixin } from "@/mixins/shared/content/moduleContentMixin";
import { contentRecordMixin } from "@/mixins/shared/content/contentRecordMixin";
import { principalMixin } from "@/mixins/shared/user/principalMixin";

/**
 * This mixin serves as a base class for Record *Content*.vue components
 */
export const moduleContentRecordMixin = {
  mixins: [moduleContentMixin, contentRecordMixin, principalMixin],
  data() {
    return {
      expandedPreview: false
    };
  },
  computed: {
    /**
     * compute current record Id
     * @return {number|number}
     */
    recordId() {
      return this.record?.id ?? -1;
    },

    /**
     * compute current record version
     * @return {*|number}
     */
    recordVersion() {
      return this.record?.version ?? -1;
    },

    /**
     * compute preview Record Version
     * @return {number}
     */
    previewRecordVersion() {
      return previewRecordVersion(this.record);
    },

    /**
     * compute whether the current record-file can be previewed by Questys Web Document Viewer
     * @return {boolean|Boolean}
     */
    visibleDocumentViewerPreview() {
      if (!this.visiblePreviewTab) {
        return false;
      }
      return canPreviewInDocumentViewer(this.record, this.principal);
    },

    /**
     * get current record's workflow project Count
     * @return {number}
     */
    projectCount() {
      return this.projects?.length ?? 0;
    },

    /**
     * compute Record - Projects Tab visibility
     * @return {boolean} true if Record - Projects Tab is visible
     */
    visibleProjectsTab() {
      return (
        this.isAvailableProjectsModule &&
        (this.record?.id ?? -1) > 0 &&
        this.projectCount > 0
      );
    }
  },
  methods: {
    onExpandedPreview(value) {
      this.expandedPreview = value;
    }
  }
};
