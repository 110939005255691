import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

/**
 * appMixin contains options (adhere to the DRY principle) &
 * code re-usability for Application
 */
export const appMixin = {
  computed: {
    ...mapGetters({
      appModuleName: "appModuleName",
      appModuleTitle: "appModuleTitle",
      appModuleIcon: "appModuleIcon"
    })
  }
};
